import axios, { AxiosResponse } from 'axios'
import * as Models from './model/models'
import { TokenRefreshRequest, applyAuthTokenInterceptor, setAuthTokens, clearAuthTokens, isLoggedIn } from 'axios-jwt'
import { CompetenceDocumentDirectoryWithParent } from './model/models'
import {
  ArrayResultsType,
  CurrentAttemptsType,
  CurrentDataDetailResultType,
  CurrentDataDetailType,
  CurrentTimerType,
  EditGroup,
  EditSurvey,
  ParticipantsAttemptsAnswersType,
  ParticipantsPayloadType,
  QuestionList,
  QuestionsList,
  ResultTest,
  SectionFull,
  SectionGroup,
  SectionType,
  Statuses,
  SurveyAdminType,
  TestQuestionType,
  TestsDataType,
  TimerStateType,
} from '../components/survey/types'
import { PaginatedResponse } from '../store/slices/surveyAdminSlice'
import { QuestionEditApi } from '../components/survey/survey-questions/map/question-edit-map'
import { VotingListItemApi } from '../components/voting/maps/to-votings-list-map'
import { CurrentVotingApi } from '../components/voting/maps/to-current-voting-map'
import { VoteResult } from '../components/voting/types'
import { ResultVotingApi } from '../components/voting/maps/to-result-voting-map'
import { ApiSection } from '../store/slices/sectionSlice'
import { ParticipantsSurveyType } from '../components/survey/common/participants-list/participants-list'
import { ApiEditGroup } from '../store/slices/groupSlice'
import { QuestionAdminFetchType } from '../store/slices/questionsAdminSlice'
import { FetchUserSyrveyType } from '../store/slices/surveySlice'
import { setPercent } from '../hooks/usePercentAtProgressBar'

const instance = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_ENDPOINT,
})

// tokens endpoint
const refreshTokenEndpoint = `${process.env.REACT_APP_ENDPOINT}/api/jwt/refresh/`
const accessTokenEndpoint = `${process.env.REACT_APP_ENDPOINT}/api/jwt/create/`

// define token refresh function
const requestRefresh: TokenRefreshRequest = async (refreshToken: string): Promise<string> => {
  // perform refresh
  const response = await axios.post(refreshTokenEndpoint, { refresh: refreshToken })
  return response.data.access
}

const authTokenInterceptorConfig = {
  header: 'Authorization',
  headerPrefix: 'Bearer ',
  requestRefresh,
}

// add interceptor to instance
applyAuthTokenInterceptor(instance, authTokenInterceptorConfig)

/** API method prefixes:
 *  get... - method GET
 *  create... - method POST
 *  set... - method PUT
 *  delete... - method DELETE
 */

const COMPETENCES = '/api/competences'
const REGISTER = '/api/register'
const NEWS = '/api/news'
const USERS = '/api/users'
const GALLERY = '/api/gallery'
const ALBUMS = '/albums'
const MEDIA = '/media'
const EVENTS = '/api/events'
const CONTACTS = '/api/contacts'
const FEEDBACK = '/api/feedback'
const DOCUMENTS = '/api/documents'
const DIRECTORY = '/api/directory'
const DIRECTORY_POST = '/api/directory-post'
const DIRECTORY_UPDATE_NAME = '/api/directory-update-name'
const DIRECTORY_UPDATE_PATH = '/api/directory-update-path'
const DIRECTORY_DELETE = '/api/directory-delete'
const FILE_POST = '/api/file-post'
const FILE_UPDATE_FOLDER = '/api/file-update-folder'
const FILE_DELETE = '/api/file-delete'
const FORUM = '/api/forum'
const GLOBAL_SEARCH = '/api/search'
const CHAMPIONSHIPS = '/api/championship'
const PARTICIPANTS = '/api/championship/participants'
const PARTICIPANTS_CHANGE_STATUS = '/api/championship/admin/change-state'
const FAQ_URI = '/api/faq'
const FORUM_SEARCH = '/api/forum/search'
const COMPANIES = '/api/companies'
const SURVEY_TIMER = '/api/pass-test'
const SURVEY_PERSONAL = '/api/personal-profile'
const SURVEY_TEST = '/api/test'
const SURVEY_QUESTIONS = '/api/test-questions'
const SURVEY_PASSED = '/api/pass-test-answers'
const SURVEY_SECTIONS = '/api/section'
const VOTING = '/api/poll'
const SURVEY_QUESTIONS_EDIT = '/api/question'
const GROUPS = '/api/group'
const QUESTION_FILE = '/api/xlsx/parse-file'
const QUESTION_LIST = '/api/list-questions'
const SURVEY_RESULTS = '/api/results'
const SKILLS_PASSPORT = '/api/skills-passport'

const skillsPassport = {
  saveUserChampionshipRating: (
    userId: number,
    championshipId: number,
    pointsByModule: number[],
  ): Promise<AxiosResponse> => {
    return instance.put(`${SKILLS_PASSPORT}/${userId}/${championshipId}/`, { pointsByModule: pointsByModule })
  },
  uploadModulesFile: async (championshipId: string | number, competenceId: string | number, file: File) => {
    const data = new FormData()
    data.append('file', file)

    return await instance.post(`${SKILLS_PASSPORT}/eval-structure/${championshipId}/${competenceId}/upload/`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress(progressEvent) {
        setPercent(progressEvent.loaded, progressEvent.total, 'skillsPassportModulesUploadFile')
      },
    })
  },
  getModules: async (
    championshipId: string | number,
    competenceId: string | number,
  ): Promise<AxiosResponse<Models.EvalStructure>> => {
    return await instance.get(`${SKILLS_PASSPORT}/eval-structure/${championshipId}/${competenceId}/`)
  },
  getModuleDetails: async (moduleId: string): Promise<AxiosResponse<Models.EvalModule>> => {
    return await instance.get(`${SKILLS_PASSPORT}/module/${moduleId}/`)
  },
  createModule: async (props: Models.EvalModuleRequest): Promise<AxiosResponse<Models.EvalModule>> => {
    return await instance.post(`${SKILLS_PASSPORT}/module/`, props)
  },
  updateModuleDetails: async (props: {
    moduleId: number
    evalStructure: number
    title: string
  }): Promise<AxiosResponse<Models.EvalModule>> => {
    const { moduleId, ...bodyData } = props
    return await instance.put(`${SKILLS_PASSPORT}/module/${moduleId}/`, bodyData)
  },
  deleteModuleDetails: async (moduleId: number) => {
    return await instance.delete(`${SKILLS_PASSPORT}/module/${moduleId}/`)
  },
  getCriterionDetails: async (criterionId: number): Promise<AxiosResponse<Models.ModuleCriterion>> => {
    return await instance.get(`${SKILLS_PASSPORT}/criterion/${criterionId}/`)
  },
  createCriteria: async (props: { module: number; title: string }): Promise<AxiosResponse<Models.ModuleCriterion>> => {
    return await instance.post(`${SKILLS_PASSPORT}/criterion/`, props)
  },
  updateCriterionDetails: async (props: {
    criterionId: number
    module: number
    title: string
  }): Promise<AxiosResponse<Models.ModuleCriterion>> => {
    const { criterionId, ...bodyData } = props
    return await instance.put(`${SKILLS_PASSPORT}/criterion/${criterionId}/`, bodyData)
  },
  deleteCriterionDetails: async (criterionId: number) => {
    return await instance.delete(`${SKILLS_PASSPORT}/criterion/${criterionId}/`)
  },
  createAspect: async (
    props: Models.CriterionAspectForModuleCriterionRequest,
  ): Promise<AxiosResponse<Models.CriterionAspectForModuleCriterion>> => {
    return await instance.post(`${SKILLS_PASSPORT}/aspect/`, props)
  },
  updateAspectDetails: async (
    props: Models.CriterionAspectForModuleCriterion,
  ): Promise<AxiosResponse<Models.CriterionAspectForModuleCriterion>> => {
    const { id, ...aspectData } = props
    return await instance.put(`${SKILLS_PASSPORT}/aspect/${id}/`, aspectData)
  },
  deleteAspectDetails: async (aspectId: number) => {
    return await instance.delete(`${SKILLS_PASSPORT}/aspect/${aspectId}`)
  },
}

const account = {
  login: async (payload: Models.CustomTokenObtainPair): Promise<void> => {
    const response: AuthResponse = (await axios.post(accessTokenEndpoint, payload)).data
    // save tokens to storage
    setAuthTokens({ accessToken: response.access, refreshToken: response.refresh })
  },
  logout: async (): Promise<void> => {
    try {
      await instance.delete(`${USERS}/logout/`)
    } finally {
      clearAuthTokens()
    }
  },
  clearTokens: (): void => {
    clearAuthTokens()
  },
  getProfile: async (): Promise<AxiosResponse | undefined> => {
    //  if there are saved tokens in the browser
    if (isLoggedIn()) {
      return await instance.get(`${USERS}/me/`)
    }
  },
  resetPassword: (payload: Models.SendEmailReset): Promise<AxiosResponse> =>
    instance.post(`${USERS}/reset_password/`, payload),
  passwordConfirm: (payload: Models.PasswordResetConfirm): Promise<AxiosResponse> =>
    instance.post(`${USERS}/reset_password_confirm/`, payload),
}

const competences = {
  getCompetences: <T>(): Promise<AxiosResponse<T>> => instance.get(`${COMPETENCES}/`),
  getCompetence: (id: string): Promise<AxiosResponse<Models.CompetenceDetail>> => instance.get(`${COMPETENCES}/${id}/`),
  getCompetenceDocs: (
    payload: GetCompetenceDocsPayload,
  ): Promise<AxiosResponse<Models.PaginatedCompetenceDocumentList>> =>
    instance.get(`${COMPETENCES}/${payload.id}/documents/?limit=${payload.limit}&offset=${payload.offset}`),
  getDivisionExperts: (id: string): Promise<AxiosResponse<Array<Models.UserWSBase>>> =>
    instance.get(`${COMPETENCES}/${id}/division_experts/`),
  getCompetenceStatistic: (id: string): Promise<AxiosResponse<Array<Models.CompetenceVisitStatistics>>> =>
    instance.get(`${COMPETENCES}/${id}/statistic`),
  addCompetenceStatistic: (id: string): Promise<AxiosResponse> => instance.post(`${COMPETENCES}/${id}/statistic/`),
  checkNewDocuments: (id: string) => instance.get(`${COMPETENCES}/${id}/documents-status`),
}

const register = {
  registrationApplication: (payload: Models.RegistrationApplicationPost): Promise<AxiosResponse> =>
    instance.post(REGISTER + '/', payload),
}

const main = {
  getMainPageSlider: (): Promise<AxiosResponse<Models.MainPageSlider[]>> => instance.get('/api/mainPageSlider/'),
  getPage: (slug: string): Promise<AxiosResponse<Models.Page>> => instance.get(`/api/pages/${slug}/`),
}

const news = {
  getNews: (payload: GetNewsPayload): Promise<AxiosResponse<Models.PaginatedNewsList>> =>
    instance.get(
      `${NEWS}/?limit=${payload.limit || 20}&offset=${payload.offset || 0}${
        payload.year ? '&year=' + payload.year : ''
      }${payload.publishOnMainPage ? '&publish_on_main_page=' + payload.publishOnMainPage : ''}`,
    ),
  getNewsYears: (): Promise<AxiosResponse<Models.NewsYears>> => instance.get(`${NEWS}/years/`),
  getNewsDetail: (id: number): Promise<AxiosResponse<Models.NewsDetail>> => instance.get(`${NEWS}/${id}/`),
}

const events = {
  getEvents: (payload: GetEventsPayload): Promise<AxiosResponse<Models.PaginatedEventList>> =>
    instance.get(
      `${EVENTS}/?limit=${payload.limit || 20}&offset=${payload.offset || 0}${
        payload.periods ? '&periods=' + payload.periods : ''
      }${payload.bannerOnMainPage ? '&banner_on_main_page=' + payload.bannerOnMainPage : ''}`,
    ),
  getEventDetail: (id: number): Promise<AxiosResponse<Models.EventDetail>> => instance.get(`${EVENTS}/${id}/`),
}

const gallery = {
  getAlbums: (payload: GetAlbumsPayload): Promise<AxiosResponse<Models.PaginatedAlbumList>> =>
    instance.get(`${GALLERY}${ALBUMS}/?limit=${payload.limit || 7}&offset=${payload.offset || 0}`),
  getAlbum: (payload: GetAlbumPayload): Promise<AxiosResponse<Models.AlbumDetail>> =>
    instance.get(`${GALLERY}${ALBUMS}/${payload.id}/`),
  getMedia: (payload: GetMediaPayload): Promise<AxiosResponse<Models.PaginatedAlbumMediaList>> =>
    instance.get(`${GALLERY}${ALBUMS}/${payload.id}${MEDIA}/?limit=${payload.limit}&offset=${payload.offset}`),
}

const contacts = {
  getContacts: (payload: Paginated): Promise<AxiosResponse<Models.PaginatedContactList>> =>
    instance.get(`${CONTACTS}/?limit=${payload.limit || 10}&offset=${payload.offset || 0}`),
}

const feedback = {
  postFeedback: (payload: Models.FeedbackMsg): Promise<AxiosResponse> => instance.post(`${FEEDBACK}/`, payload),
  postDivisionExpertFeedback: (payload: Models.DivisionExpertFeedbackMsg): Promise<AxiosResponse> =>
    instance.post(`${FEEDBACK}/division_expert/`, payload),
}

const directory = {
  getDirectoryContent: (payload: GetDirectoryContentPayload): Promise<AxiosResponse<DirectoryContentResponse>> =>
    instance.get(
      `${DIRECTORY}/?competence_id=${payload.competenceId}&dir_id=${payload.dirId || ''}&limit=${
        payload.limit || ''
      }&offset=${payload.offset || ''}`,
    ),
  getDirectory: (payload: string): Promise<AxiosResponse<CompetenceDocumentDirectoryWithParent>> =>
    instance.get(`${DIRECTORY}/${payload}/`),
  postDocument: (document: {
    file: File
    parent: number
    notification?: boolean
  }): Promise<AxiosResponse<DocumerntPostResponse>> => {
    const data = new FormData()
    data.append('document', document.file)
    data.append('parent', document.parent.toString())

    return instance.post(`${FILE_POST}/?is_notification=${document.notification ? '1' : '0'}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  movingDocument: ({ id, parent }: MovingRequest): Promise<AxiosResponse<MovingResponse>> => {
    return instance.put(`${FILE_UPDATE_FOLDER}/${id}/`, { parent: parent })
  },
  deleteDocument: (docId: string): Promise<void> => instance.delete(`${FILE_DELETE}/${docId}/`),
  postDirectory: (dirData: DirectoryPostResponse): Promise<AxiosResponse<DirectoryPostResponse>> =>
    instance.post(`${DIRECTORY_POST}/`, dirData),
  renameDirectory: (dirId: string, dirName: string): Promise<AxiosResponse<DirectoryPostResponse>> =>
    instance.put(`${DIRECTORY_UPDATE_NAME}/${dirId}/`, { name: dirName }),
  movingDirectory: ({ id, parent }: MovingRequest): Promise<AxiosResponse<MovingResponse>> => {
    return instance.put(`${DIRECTORY_UPDATE_PATH}/${id}/`, { parent: parent })
  },
  deleteDirectory: (dirId: string): Promise<void> => instance.delete(`${DIRECTORY_DELETE}/${dirId}/`),
  getCompetenceDocsStatistic: (id: string): Promise<AxiosResponse<Array<Models.DocumentDownloadStatistics>>> =>
    instance.get(`${DOCUMENTS}/${id}/statistic`),
  addCompetenceDocsStatistic: (id: string): Promise<AxiosResponse> => instance.post(`${DOCUMENTS}/${id}/statistic/`),
}

const captcha = {
  getCaptcha: (): Promise<AxiosResponse<Captcha>> => instance.post(`/api/captcha/`),
}

const forum = {
  getTopics: (payload: GetTopicsPayload): Promise<AxiosResponse<Models.PaginatedForumTopicReadList>> =>
    instance.get(
      `${FORUM}/topic/?competence=${payload.competence}&limit=${payload.limit || 10}&offset=${payload.offset || 0}`,
    ),
}

const forumTopic = {
  getTopic: (id: number): Promise<AxiosResponse<Models.ForumTopicReadDetail>> => instance.get(`${FORUM}/topic/${id}/`),
  postTopic: (payload: Partial<Models.ForumTopicCreate>): Promise<AxiosResponse> =>
    instance.post(`${FORUM}/topic/`, payload),
  getTopicPollOptionsResults: (topicId: number): Promise<AxiosResponse<Models.PaginatedTopicPollOptionsResultsList>> =>
    instance.get(`${FORUM}/topic/${topicId}/poll/options-results/?limit=1000&offset=0`),
  getTopicPollCustomOptionsResults: (
    topicId: number,
  ): Promise<AxiosResponse<Models.PaginatedTopicPollCustomOptionsResultsList>> =>
    instance.get(`${FORUM}/topic/${topicId}/poll/custom-results/?limit=1000&offset=0`),
  postTopicPoll: (payload: Models.ForumPollTopicCreate): Promise<AxiosResponse<Models.ForumPollTopicCreate>> =>
    instance.post(`${FORUM}/topic/poll/`, payload),
  postTopicPollVote: (
    topicId: number,
    payload: Omit<Models.TopicPollVote, 'totalVotesCount'>,
  ): Promise<AxiosResponse<Models.TopicPollVote>> => instance.post(`${FORUM}/topic/${topicId}/poll/vote/`, payload),
  putTopic: (
    topicId: number,
    payload: Models.TopicUpdateByAuthor | Models.TopicUpdateByModerator,
  ): Promise<AxiosResponse<Models.TopicUpdateByAuthor | Models.TopicUpdateByModerator>> =>
    instance.put(`${FORUM}/topic/${topicId}/`, payload),
  deleteTopic: (id: number): Promise<AxiosResponse> => instance.delete(`${FORUM}/topic/${id}/`),
  getInfo: (id: number): Promise<AxiosResponse<Models.ForumInfo>> => instance.get(`${FORUM}/info/?competence=${id}`),
}

const forumComments = {
  getComments: (payload: GetForumCommentsPayload): Promise<AxiosResponse<Models.PaginatedForumCommentReadList>> =>
    instance.get(`${FORUM}/comment/?limit=${payload.limit || 10}&offset=${payload.offset || 0}&topic=${payload.topic}`),
  postComment: (payload: Partial<Models.ForumCommentCreate>): Promise<AxiosResponse<Models.ForumCommentRead>> =>
    instance.post(`${FORUM}/comment/`, payload),
  putComment: (
    payload: { id: number } & Models.ForumCommentUpdate,
  ): Promise<AxiosResponse<Models.ForumCommentUpdate>> =>
    instance.put(`${FORUM}/comment/${payload.id}/`, { message: payload.message }),
  deleteComment: (id: number): Promise<AxiosResponse> => instance.delete(`${FORUM}/comment/${id}/`),
  postAttachments: (payload: AttachmentsPayload) => {
    const { uploadedFile, commentId } = payload
    const data = new FormData()
    data.append('comment', commentId.toString())
    uploadedFile && data.append('uploadedFile', uploadedFile)
    return instance.post(`${FORUM}/attachments/`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  deleteAttachment: (id: number) => instance.delete(`${FORUM}/attachments/${id}/`),
}

const forumGroup = {
  getGroups: (payload: GetForumGroupsPayload): Promise<AxiosResponse<Models.PaginatedForumGroupList>> =>
    instance.get(
      `${FORUM}/group/?competence=${payload.competence}&limit=${payload.limit || 20}&offset=${payload.offset || 0}`,
    ),
}

const forumSearch = {
  getSearch: (payload: ForumSearchPayload): Promise<AxiosResponse<Models.PaginatedForumSearchResultList>> => {
    return instance.get(
      `${FORUM_SEARCH}/?competence=${payload.competence}&expr=${payload.expr}&limit=${payload.limit || 20}&offset=${
        payload.offset || 0
      }`,
    )
  },
}

const globalSearch = {
  getSearch: (payload: GlobalSearchPayload): Promise<AxiosResponse<Models.SearchGroup[]>> =>
    instance.get(`${GLOBAL_SEARCH}/?expr=${encodeURIComponent(payload.expr)}`),
  getSearchByGroup: (payload: GlobalSearchByGroupPayload): Promise<AxiosResponse<Models.PaginatedSearchGroupList>> =>
    instance.get(
      `${GLOBAL_SEARCH}/${payload.modelName}_/?expr=${encodeURIComponent(payload.expr)}&limit=${
        payload?.limit || 50
      }&offset=${payload?.offset || 0}`,
    ),
}

const championships = {
  getChampionshipsTypes: (
    payload: GetChampionshipsTypesPayload,
  ): Promise<AxiosResponse<Models.PaginatedChampionshipTypeList>> =>
    instance.get(`${CHAMPIONSHIPS}/type/?limit=${payload.limit || 16}&offset=${payload.offset || 0}`),
  getChampionshipsSubType: (payload: string): Promise<AxiosResponse<Models.ChampionshipSubTypeWithType>> =>
    instance.get(`${CHAMPIONSHIPS}/subtypes/${payload}/`),
  getChampionshipsList: (
    payload: GetChampionshipsListPayload,
  ): Promise<AxiosResponse<Models.PaginatedChampionshipList>> =>
    instance.get(
      `${CHAMPIONSHIPS}/?limit=${payload.limit || 16}&offset=${payload.offset || 0}&periods=${
        payload.periods || ''
      }&sub_type=${payload.subType}`,
    ),
  getChampionshipDetail: (payload: { id: number }): Promise<AxiosResponse<Models.ChampionshipDetail>> =>
    instance.get(`${CHAMPIONSHIPS}/${payload.id}/`),
  getChampionshipDocs: (
    payload: GetChampionshipDocsPayload,
  ): Promise<AxiosResponse<Models.PaginatedChampionshipDocumentList>> =>
    instance.get(`${CHAMPIONSHIPS}/${payload.id}/docs/?limit=${payload.limit}&offset=${payload.offset || 0}`),
  championshipRegistration: (payload: ChampionshipRegistrationPayload): Promise<AxiosResponse> => {
    const { championshipId, participantId, ...rest } = payload
    return instance.post(`${CHAMPIONSHIPS}/register/${championshipId}/${participantId}/`, rest)
  },
  getLastChampionship: (): Promise<AxiosResponse<Models.Championship>> => instance.get(`${CHAMPIONSHIPS}/last/`),
}

const participants = {
  getParticipants: (payload: GetParticipantsPayload): Promise<AxiosResponse<Models.ChampionshipsParticipants[]>> =>
    instance.get(`${PARTICIPANTS}/${payload.championshipId}/${payload.status}/`),
  searchParticipants: (
    payload: SearchParticipantsPayload,
  ): Promise<AxiosResponse<Models.ChampionshipsParticipants[]>> =>
    instance.get(`${PARTICIPANTS}/${payload.championshipId}/${payload.status}/${payload.search}/`),
  changeStatusParticipants: (payload: ChangeStatusParticipantsPayload): Promise<AxiosResponse> =>
    instance.put(`${PARTICIPANTS_CHANGE_STATUS}/${payload.id}/${payload.status}/`),
  exportParticipants: (idChamp: string): Promise<AxiosResponse<File>> =>
    instance.get(`/api/export-users/${idChamp}`, { responseType: 'blob' }),
}

const userProfile = {
  get: (payload: GetUserProfilePayload): Promise<AxiosResponse<Models.UserWSDetail>> =>
    instance.get(`${USERS}/${payload.id}/profile/`),
  put: (id: number, payload: Models.UserWSDetail): Promise<AxiosResponse<Models.UserWSDetail>> =>
    instance.put(`${USERS}/${id}/profile/`, payload),
  postSetPassword: (payload: Models.SetPassword): Promise<AxiosResponse> =>
    instance.post(`${USERS}/set_password/`, payload),
  putCreateAvatar: (payload: PutUserProfilePayloadChangeAvatar): Promise<AxiosResponse> => {
    const { avatar, id } = payload
    const data = new FormData()
    if (avatar) {
      avatar && data.append('avatar', avatar)
      return instance.put(`${USERS}/${id}/profile/`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    } else {
      return instance.put(`${USERS}/${id}/profile/`, { avatar: null })
    }
  },
  getPublicProfile: (payload: GetUserPublicProfilePayload): Promise<AxiosResponse<Models.UserPublic>> =>
    instance.get(`${USERS}/${payload.id}/public/`),
  putNotifySetting: () => instance.put(`${USERS}/email-notify/`),
}

const FAQ = {
  get: (): Promise<AxiosResponse<Models.FaqQuestion[]>> => instance.get(`${FAQ_URI}/`),
}

const companies = {
  get: (): Promise<AxiosResponse<Models.Company[]>> => instance.get(`${COMPANIES}/`),
}

const sections = {
  get: (payload: Paginated): Promise<AxiosResponse<SectionType[]>> =>
    instance.get(`${SURVEY_SECTIONS}/?limit=${payload.limit}&offset=${payload.offset}`),
  getFull: (id: number): Promise<AxiosResponse<SectionFull>> => instance.get(`${SURVEY_SECTIONS}/${id}/`),
  update: (section: ApiSection): Promise<AxiosResponse<void>> =>
    instance.put(`${SURVEY_SECTIONS}/${section.id}/`, section),
  create: (section: ApiSection): Promise<AxiosResponse<SectionFull>> => instance.post(`${SURVEY_SECTIONS}/`, section),
  deleteSection: (id: number): Promise<AxiosResponse<SectionFull>> => instance.delete(`${SURVEY_SECTIONS}/${id}/`),
}

const survey = {
  getTimer: (userId: number, attemptId: number): Promise<AxiosResponse<CurrentTimerType>> =>
    instance.get(`${SURVEY_TIMER}/${userId}/${attemptId}/timer`),

  runTimer: (userId: number, attemptId: number): Promise<AxiosResponse<TimerStateType>> =>
    instance.put(`${SURVEY_TIMER}/run-timer/${userId}/${attemptId}`, { timerState: 2 }),

  stopTimer: (userId: number, attemptId: number): Promise<AxiosResponse<TimerStateType>> =>
    instance.put(`${SURVEY_TIMER}/stop-timer/${userId}/${attemptId}`, { timerState: 1 }),

  getTestsForUser: (payload: FetchUserSyrveyType): Promise<AxiosResponse<TestsDataType>> =>
    instance.get(`${SURVEY_PERSONAL}/tests/${payload.userId}/?status=${payload.status}`),

  getUserAttempts: (userId: number): Promise<AxiosResponse<CurrentAttemptsType>> =>
    instance.get(`${SURVEY_PERSONAL}/attempts-history/${userId}`),

  getQuestions: (surveyId: number): Promise<AxiosResponse<PaginatedResponse<TestQuestionType>>> =>
    instance.get(`${SURVEY_QUESTIONS}/${surveyId}/question/`),

  getAllQuestions: (): Promise<AxiosResponse<PaginatedResponse<TestQuestionType>>> =>
    instance.get(`${SURVEY_QUESTIONS_EDIT}/?limit=500000&offset=0`),
  getQuestionsBySection: (sectionId: number[]): Promise<AxiosResponse<QuestionEditApi[]>> =>
    instance.get(`${SURVEY_QUESTIONS_EDIT}/?section_id=${sectionId.join()}`),
  getQuestionsByCompetence: (competenceId: number[]): Promise<AxiosResponse<TestQuestionType[]>> =>
    instance.get(`${SURVEY_QUESTIONS_EDIT}/?competence_id=${competenceId.join()}`),

  getQuestionsEdit: (payload: QuestionAdminFetchType): Promise<AxiosResponse<PaginatedResponse<QuestionEditApi>>> =>
    instance.get(
      `${SURVEY_QUESTIONS_EDIT}/?limit=${payload.params.limit}&offset=${payload.params.offset}${
        payload.params.text && `&text=${payload.params.text}`
      }${
        payload.params.section_id && payload.params.section_id.length !== 0
          ? `&section_id=${payload.params.section_id.join()}`
          : ''
      }${
        payload.params.competences && payload.params.competences.length !== 0
          ? `&competence_id=${payload.params.competences.join()}`
          : ''
      }${payload.type && payload.type.length !== 0 ? `&type=${payload.type.join(`&type=`)}` : ''}`,
    ),
  deleteQuestionEdit: (payload: number): Promise<AxiosResponse> =>
    instance.delete(`${SURVEY_QUESTIONS_EDIT}/${payload}`),
  updateQuestionEdit: (payload: QuestionEditApi): Promise<AxiosResponse<QuestionEditApi>> =>
    instance.put(`${SURVEY_QUESTIONS_EDIT}/${payload.id}/`, payload),
  sendQuestionEdit: (payload: QuestionEditApi): Promise<AxiosResponse<QuestionEditApi>> =>
    instance.post(`${SURVEY_QUESTIONS_EDIT}/`, payload),

  getQuestionEdit: (id: number): Promise<AxiosResponse<QuestionEditApi>> =>
    instance.get(`${SURVEY_QUESTIONS_EDIT}/${id}/`),

  getTestDetail: (userId: number, surveyId: number): Promise<AxiosResponse<CurrentDataDetailType>> =>
    instance.get(`${SURVEY_TEST}/${userId}/${surveyId}/show/`),

  getAdminList: (payload: Paginated, status: Statuses): Promise<AxiosResponse<SurveyAdminType[]>> =>
    instance.get(`${SURVEY_TEST}/?status=${status}&limit=${payload.limit}&offset=${payload.offset}`),
  getEditSurvey: (id: number): Promise<AxiosResponse<EditSurvey>> => instance.get(`${SURVEY_TEST}/${id}/`),
  sendEditSurvey: (payload: EditSurvey): Promise<AxiosResponse<EditSurvey>> =>
    instance.post(`${SURVEY_TEST}/`, payload),
  updateEditSurvey: (payload: EditSurvey): Promise<AxiosResponse> =>
    instance.put(`${SURVEY_TEST}/${payload.id}/`, payload),
  deleteEditSurvey: (payload: number): Promise<AxiosResponse> => instance.delete(`${SURVEY_TEST}/${payload}/`),

  getUserAnswers: (
    userId: number,
    surveyId: number,
    attemptId: number,
  ): Promise<AxiosResponse<ParticipantsAttemptsAnswersType>> =>
    instance.get(`${SURVEY_PASSED}/${userId}/${surveyId}/${attemptId}/participant-answer/`),
  sendTestAnswers: (resultObj: ArrayResultsType, userId: number, attemptId: number): Promise<AxiosResponse<void>> =>
    instance.post(`${SURVEY_PASSED}/${userId}/${attemptId}/participant-answer`, resultObj),
}

const results = {
  getFull: (id: number): Promise<AxiosResponse<CurrentDataDetailResultType>> =>
    instance.get(`${SURVEY_RESULTS}/${id}/`),
  get: (
    offset: number,
    size: number,
    query?: string,
    id?: number,
  ): Promise<AxiosResponse<PaginatedResponse<ResultTest>>> =>
    instance.get(
      `${SURVEY_RESULTS}/?offset=${offset}&limit=${size}${id && !isNaN(id) ? `&test_id=${id}` : ''}${
        query ? `&test=${query}&company=${query}` : ''
      }`,
    ),
  exportFile: (id: number): Promise<AxiosResponse<File>> => {
    if (id) return instance.get(`${SURVEY_RESULTS}/${id}/export/`, { responseType: 'blob' })
    else return instance.get(`${SURVEY_RESULTS}/export/`, { responseType: 'blob' })
  },
}

const questions = {
  importFile: (payload: File): Promise<AxiosResponse> => {
    const data = new FormData()
    data.append('file', payload)
    return instance.post(`${QUESTION_FILE}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  uploadImage: (payload: IdFile): Promise<AxiosResponse<void>> => {
    const data = new FormData()
    data.append('file', payload.file)
    return instance.put(`${SURVEY_QUESTIONS_EDIT}/${payload.id}/upload-file/`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  exportFile: (): Promise<AxiosResponse<File>> => instance.get('/api/question/export/', { responseType: 'blob' }),
}

const groups = {
  get: (payload: Paginated): Promise<AxiosResponse<SectionGroup>> =>
    instance.get(`${GROUPS}/?limit=${payload.limit}&offset=${payload.offset}`),
  getDetail: (payload: number): Promise<AxiosResponse<EditGroup>> => instance.get(`${GROUPS}/${payload}/`),
  deleteGroup: (id: number): Promise<AxiosResponse<void>> => instance.delete(`${GROUPS}/${id}/`),
  getParticipants: (payload: ParticipantsPayloadType = {}): Promise<AxiosResponse<ParticipantsSurveyType[]>> =>
    instance.get(`${USERS}/profiles/`, {
      params: {
        championship_id: payload.championshipId,
        championship_points: payload.championshipPoints,
        competence_id:
          Array.isArray(payload.competenceId) && payload.competenceId.length
            ? payload.competenceId.join()
            : payload.competenceId,
        limit: payload.limit,
        offset: payload.offset,
      },
    }),
  update: (payload: ApiEditGroup): Promise<AxiosResponse<EditGroup>> =>
    instance.put(`${GROUPS}/${payload.id}/`, payload),
  create: (payload: ApiEditGroup): Promise<AxiosResponse<EditGroup>> => instance.post(`${GROUPS}/`, payload),
}

const questionList = {
  getQuestionsByList: (listId: number): Promise<AxiosResponse<QuestionsList>> =>
    instance.get(`${QUESTION_LIST}/${listId}/`),
  getQuestionsLists: (): Promise<AxiosResponse<QuestionList[]>> => instance.get(`${QUESTION_LIST}/`),
  exportFile: (id: number): Promise<AxiosResponse<File>> =>
    instance.get(`${QUESTION_LIST}/${id}/export`, { responseType: 'blob' }),
  sendList: (list: QuestionsList): Promise<AxiosResponse<void>> => instance.post(`${QUESTION_LIST}/`, list),
  updateList: (list: QuestionsList): Promise<AxiosResponse<void>> => instance.put(`${QUESTION_LIST}/${list.id}/`, list),
}

const voting = {
  getActive: (paginated: Paginated): Promise<AxiosResponse<PaginatedResponse<VotingListItemApi>>> =>
    instance.get(`${VOTING}/active?limit=${paginated.limit}&offset=${paginated.offset}`),
  getLastActive: (): Promise<AxiosResponse<VotingListItemApi>> => instance.get(`${VOTING}/active/last`),
  getEnded: (paginated: Paginated): Promise<AxiosResponse<PaginatedResponse<VotingListItemApi>>> =>
    instance.get(`${VOTING}/completed?limit=${paginated.limit}&offset=${paginated.offset}`),
  getAll: (paginated: Paginated): Promise<AxiosResponse<PaginatedResponse<VotingListItemApi>>> =>
    instance.get(`${VOTING}?limit=${paginated.limit}&offset=${paginated.offset}`),
  get: (id: number): Promise<AxiosResponse<CurrentVotingApi>> => instance.get(`${VOTING}/${id}`),
  sendAnswer: (answers: VoteResult): Promise<AxiosResponse<void>> =>
    instance.post(`${VOTING}/${answers.poll}/poll-vote/`, answers),
  getResult: (id: number): Promise<AxiosResponse<ResultVotingApi[]>> =>
    instance.get(`${VOTING}/${id}/results/?limit=0&offset=0`),
  getResultExport: (id: number): Promise<AxiosResponse<File>> =>
    instance.get(`${VOTING}/${id}/results/export/`, { responseType: 'blob' }),
}

export default {
  skillsPassport,
  competences,
  register,
  main,
  news,
  account,
  gallery,
  events,
  contacts,
  feedback,
  directory,
  captcha,
  forum,
  forumTopic,
  forumComments,
  forumGroup,
  forumSearch,
  globalSearch,
  championships,
  participants,
  userProfile,
  FAQ,
  companies,
  survey,
  sections,
  voting,
  groups,
  questions,
  results,
  questionList,
}
