import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import appAPI from '../../api/api'
import {
  CriterionAspectForModuleCriterion,
  CriterionAspectForModuleCriterionRequest,
  EvalModule,
  EvalModuleRequest,
  EvalStructure,
  ModuleCriterion,
} from '../../api/model/models'
import { RootState } from '../store'

interface GetRatingModulesArgs {
  competenciesSelectID: string | number
  championshipsSelectID: string | number
  file: File
}

interface TInitialState {
  ratingEntryStatus: 'customization' | 'estimation' | 'blocked'
  competenciesSelectID: number | undefined
  championshipsSelectID: number | undefined
  addNewModule: EvalModule | undefined
  uploadFileLoading: boolean
  uploadFileError: boolean
  ratingModules: {
    loading: boolean
    data: EvalStructure | null | undefined
  }
  ratingModuleDetail: {
    loading: boolean
    data: EvalModule | null | undefined
    updateStatus: 'success' | 'error' | undefined
    criteriaDeleteStatus: 'success' | 'error' | undefined
  }
  ratingCriterionDetails: {
    loading: boolean
    data: ModuleCriterion | null | undefined
  }
}

const initialState: TInitialState = {
  ratingEntryStatus: 'customization',
  competenciesSelectID: undefined,
  championshipsSelectID: undefined,
  addNewModule: undefined,
  uploadFileLoading: false,
  uploadFileError: false,
  ratingModules: {
    loading: false,
    data: undefined,
  },
  ratingModuleDetail: {
    loading: false,
    data: undefined,
    updateStatus: undefined,
    criteriaDeleteStatus: undefined,
  },
  ratingCriterionDetails: {
    loading: false,
    data: undefined,
  },
}

const uploadModulesFile = createAsyncThunk('uploadModulesFile', async (props: GetRatingModulesArgs, { dispatch }) => {
  const { championshipsSelectID, competenciesSelectID, file } = props

  const uploadResult = await appAPI.skillsPassport.uploadModulesFile(championshipsSelectID, competenciesSelectID, file)

  dispatch(ratingSliceActions.getRatingModules({ championshipsSelectID, competenciesSelectID }))

  return uploadResult
})

const getRatingModules = createAsyncThunk('getRatingModules', (props: Omit<GetRatingModulesArgs, 'file'>) => {
  return appAPI.skillsPassport.getModules(props.championshipsSelectID, props.competenciesSelectID)
})

const getRatingModuleDetails = createAsyncThunk('getRatingModuleDetails', (moduleId: string) => {
  return appAPI.skillsPassport.getModuleDetails(moduleId)
})

const createRatingModule = createAsyncThunk('createRatingModule', async (props: EvalModuleRequest) => {
  return await appAPI.skillsPassport.createModule(props)
})

const updateRatingModuleDetails = createAsyncThunk(
  'updateRatingModuleDetails',
  async (props: { moduleId: number; evalStructure: number; title: string }, { dispatch, getState }) => {
    const updateModule = await appAPI.skillsPassport.updateModuleDetails(props)

    const state = getState() as RootState
    const moduleId = state.rating.ratingModuleDetail.data?.id

    dispatch(ratingSliceActions.getRatingModuleDetails(String(moduleId)))

    return updateModule
  },
)

const deleteRatingModuleDetails = createAsyncThunk(
  'deleteRatingModuleDetails',
  async (moduleId: number, { dispatch, getState }) => {
    await appAPI.skillsPassport.deleteModuleDetails(moduleId)

    const state = getState() as RootState

    const championshipsSelectID = state.rating.championshipsSelectID
    const competenciesSelectID = state.rating.competenciesSelectID

    if (championshipsSelectID && competenciesSelectID)
      dispatch(ratingSliceActions.getRatingModules({ championshipsSelectID, competenciesSelectID }))
  },
)

const getRatingCriterionDetails = createAsyncThunk('getCriterionDetails', (criterionId: number) => {
  return appAPI.skillsPassport.getCriterionDetails(criterionId)
})

const createRatingCriteria = createAsyncThunk(
  'createRatingCriteria',
  async (props: { module: number; title: string }, { dispatch }) => {
    const addCriteria = await appAPI.skillsPassport.createCriteria(props)

    const moduleId = addCriteria.data.module

    dispatch(ratingSliceActions.getRatingModuleDetails(String(moduleId)))

    return addCriteria
  },
)

const updateRatingCriterionDetails = createAsyncThunk(
  'updateRatingCriterionDetails',
  async (props: { criterionId: number; module: number; title: string }, { dispatch }) => {
    const updateCriteria = await appAPI.skillsPassport.updateCriterionDetails(props)

    const moduleId = updateCriteria.data.module

    dispatch(ratingSliceActions.getRatingModuleDetails(String(moduleId)))

    return updateCriteria
  },
)

const deleteRatingCriterionDetails = createAsyncThunk(
  'deleteRatingCriterionDetails',
  async (props: { criterionId: number; moduleId: number }, { dispatch }) => {
    const { criterionId, moduleId } = props
    await appAPI.skillsPassport.deleteCriterionDetails(criterionId)

    dispatch(ratingSliceActions.getRatingModuleDetails(String(moduleId)))
  },
)

const createRatingAspect = createAsyncThunk(
  'createRatingAspect',
  async (props: CriterionAspectForModuleCriterionRequest, { dispatch }) => {
    const createAspect = await appAPI.skillsPassport.createAspect(props)

    const criterionId = createAspect.data.criterion

    dispatch(ratingSliceActions.getRatingCriterionDetails(criterionId))

    return createAspect
  },
)

const updateRatingAspectDetails = createAsyncThunk(
  'updateRatingAspectDetails',
  async (props: CriterionAspectForModuleCriterion, { dispatch }) => {
    const updateAspect = await appAPI.skillsPassport.updateAspectDetails(props)

    const criterionId = updateAspect.data.criterion

    dispatch(ratingSliceActions.getRatingCriterionDetails(criterionId))

    return updateAspect
  },
)

const deleteRatingAspectDetails = createAsyncThunk(
  'deleteRatingAspectDetails',
  async (aspectId: number, { dispatch, getState }) => {
    await appAPI.skillsPassport.deleteAspectDetails(aspectId)

    const state = getState() as RootState

    const criteriaId = state.rating.ratingCriterionDetails.data?.id

    if (criteriaId) dispatch(ratingSliceActions.getRatingCriterionDetails(criteriaId))
  },
)

const ratingSlice = createSlice({
  name: 'rating',
  initialState: initialState,
  reducers: {
    setRatingEntryStatus(state, { payload }: PayloadAction<'customization' | 'estimation' | 'blocked'>) {
      state.ratingEntryStatus = payload
    },
    setCompetenciesSelectID(state, { payload }: PayloadAction<number>) {
      state.competenciesSelectID = payload
    },
    setChampionshipsSelectID(state, { payload }: PayloadAction<number>) {
      state.championshipsSelectID = payload
    },
    clearRatingModuleDetail(state) {
      state.ratingModuleDetail.data = undefined
    },
    clearRatingCriterionDetail(state) {
      state.ratingCriterionDetails.data = undefined
    },
    clearRatingNewModule(state) {
      state.addNewModule = undefined
    },
    clearUpdateModuleDetailsStatus(state) {
      state.ratingModuleDetail.updateStatus = undefined
    },
  },
  extraReducers(builder) {
    builder
      .addCase(uploadModulesFile.pending, (state) => {
        state.uploadFileLoading = true
      })
      .addCase(uploadModulesFile.fulfilled, (state) => {
        state.uploadFileLoading = false
      })
      .addCase(uploadModulesFile.rejected, (state) => {
        state.uploadFileLoading = false
        state.uploadFileError = true
      })
      .addCase(getRatingModules.pending, (state) => {
        state.ratingModules.loading = true
        state.ratingModules.data = undefined
      })
      .addCase(getRatingModules.fulfilled, (state, { payload }) => {
        state.ratingModules.loading = false
        state.ratingModules.data = payload.data
      })
      .addCase(getRatingModules.rejected, (state) => {
        state.ratingModules.loading = false
        state.ratingModules.data = undefined
      })
      .addCase(getRatingModuleDetails.pending, (state) => {
        state.ratingModuleDetail.loading = true
      })
      .addCase(getRatingModuleDetails.fulfilled, (state, { payload }) => {
        state.ratingModuleDetail.loading = false

        if (payload.data.moduleCriterions && payload.data.moduleCriterions?.length > 0) {
          const sortedData = payload.data.moduleCriterions.sort((a, b) => a.id - b.id)
          state.ratingModuleDetail.data = { ...payload.data, moduleCriterions: sortedData }
        } else {
          state.ratingModuleDetail.data = payload.data
        }

        // if (state.ratingModuleDetail.criteriaDeleteStatus !== undefined)
        //   state.ratingModuleDetail.criteriaDeleteStatus = undefined
      })
      .addCase(getRatingModuleDetails.rejected, (state) => {
        state.ratingModuleDetail.loading = false
      })
      .addCase(createRatingModule.fulfilled, (state, { payload }) => {
        state.addNewModule = payload.data
      })
      .addCase(updateRatingModuleDetails.fulfilled, (state) => {
        state.ratingModuleDetail.updateStatus = 'success'
      })
      .addCase(updateRatingModuleDetails.rejected, (state) => {
        state.ratingModuleDetail.updateStatus = 'error'
      })
      .addCase(getRatingCriterionDetails.pending, (state) => {
        state.ratingCriterionDetails.loading = true

        if (state.ratingModuleDetail.criteriaDeleteStatus !== undefined)
          state.ratingModuleDetail.criteriaDeleteStatus = undefined
      })
      .addCase(getRatingCriterionDetails.fulfilled, (state, { payload }) => {
        state.ratingCriterionDetails.loading = false
        state.ratingCriterionDetails.data = payload.data
      })
      .addCase(getRatingCriterionDetails.rejected, (state) => {
        state.ratingCriterionDetails.loading = false
      })
      .addCase(updateRatingCriterionDetails.pending, (state) => {
        state.ratingCriterionDetails.loading = true
      })
      .addCase(updateRatingCriterionDetails.fulfilled, (state, { payload }) => {
        state.ratingCriterionDetails.loading = false
        state.ratingCriterionDetails.data = payload.data
      })
      .addCase(updateRatingCriterionDetails.rejected, (state) => {
        state.ratingCriterionDetails.loading = false
      })

      .addCase(deleteRatingCriterionDetails.pending, (state) => {
        if (state.ratingModuleDetail.criteriaDeleteStatus !== undefined)
          state.ratingModuleDetail.criteriaDeleteStatus = undefined
      })
      .addCase(deleteRatingCriterionDetails.fulfilled, (state) => {
        state.ratingModuleDetail.criteriaDeleteStatus = 'success'
      })
      .addCase(deleteRatingCriterionDetails.rejected, (state) => {
        state.ratingModuleDetail.criteriaDeleteStatus = 'error'
      })
  },
})

export default ratingSlice.reducer

export const ratingSliceActions = {
  ...ratingSlice.actions,
  uploadModulesFile,
  getRatingModules,
  getRatingModuleDetails,
  createRatingModule,
  updateRatingModuleDetails,
  deleteRatingModuleDetails,
  getRatingCriterionDetails,
  createRatingCriteria,
  updateRatingCriterionDetails,
  deleteRatingCriterionDetails,
  createRatingAspect,
  updateRatingAspectDetails,
  deleteRatingAspectDetails,
}
